//Node Modules
import React from "react";
import * as router from "@reach/router";

const SharedContent = () => (
  <>
    {console.log(router)}
    <p>Shared Content</p>
  </>
);

export default SharedContent;